export const cleanPhone = (phone: string) => ("" + phone).replace(/\D/g, "");

export const formatPhoneNumber = (phone: string) => {
  return cleanPhone(phone).replace(
    /(\d{2})(\d{3})(\d{2})(\d{2})/,
    "($1) $2-$3-$4"
  );
};

export const displayPhoneFormatter = (phone: string) => {
  return ("" + phone).replace(
    /(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/,
    "$1 ($2) $3-$4-$5"
  );
};

export const formatPhoneToSubmit = (phone: string) => {
  const cleanedPhone = cleanPhone(phone);
  if (cleanedPhone.length <= 9) {
    return "+998" + cleanedPhone;
  }
  return cleanedPhone;
};

export const removeCountryCode = (phone: string) => {
  return phone.slice(4);
};
